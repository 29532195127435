.left-container-se  {
    position: relative;
    flex-grow:100;
    width:calc(100vw - 495px);
    height:100%;
    margin-right:10px;
    box-shadow: var(--maincontent-bs);
    border-radius: var(--border-radius);
    color:unset !important;
    background-color: white;
 }

  .right-container-se {
    position: relative;
    min-width:345px;
    box-shadow: var(--maincontent-bs);
    background-color:white;
    z-index:100;
    height:100%;
    border-radius: var(--border-radius);
  }

  .content-areas-se {
    width:100%;
    position: relative;
    border: 1px solid var(--border-color);
    border-radius:3px;
    -webkit-user-select: none;     
    -moz-user-select: none; 
    -ms-user-select: none; 
    user-select: none; 
    }
  
  .simplebar-se {
    height: calc(100vh - 110px);
  }

  .typ-container-se {
    display:flex;
    align-items: center;
    height:25px;
    border-radius: 3px;
}

.typ-img-se {
    margin-left:5px;
  margin-right: 5px;
  height:17px;
  width:17px;
}

.typ-container-se:hover {
  color: #fff !important;
  background-color: var(--selected-blue) !important;
  border-color: var(--selected-blue) ;
  text-decoration: none;
  cursor: pointer;
}

.export-img{
  width:22px;
  height:22px;
  cursor:pointer;
}

.chart-name {
position:absolute !important;
text-align: center;
top: 15px !important;
width: 100%;
font-size: 12px;
font-weight: 500;
}


#statistik-sicon {
  width:20px;
  height:20px;
  position: absolute;
  bottom:5px;
  left:10px;
  z-index:1000;
display:none
}



@media print {
  @page {  
    size: auto;
  }
}


#chart [role="button"] {
  cursor: pointer;
  text-decoration: none !important;
  border:unset !important;
  outline: none !important;
  width:0px !important;
}


  @media only screen and (min-width:768px) and (max-width : 1024px) and (orientation : portrait),  
only screen and (min-width:1024px) and (max-width : 1366px) and (orientation : landscape)
{

.left-container-se {
  position: absolute;
  width:100%;
  height:calc( 100% - 60px ) !important;
  margin-right:0px;
  margin-top:60px;
  border-radius: unset;
  box-shadow: unset;
}
  
  .simplebar-se {
      height: calc(75vh - 25px);
  }

  .right-container-se {
      display:none;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      width:60vw;
      z-index:1000;
      height:75vh;
      box-shadow:0 1px 4px rgba(0,0,0,0.16), 0 1px 4px rgba(0,0,0,0.23);
  }

  #statistik-sicon {
    display:block;
    }
  

}



@media only screen and (min-width:1024px) and (max-width : 1366px) and (orientation : landscape)
{

  .right-container-se {
    width:40vw;
    height:80vh;
}

.simplebar-se {
  height: calc(80vh - 25px);
}



}



@media only screen and (max-width : 767px) and (orientation : portrait)  {

.left-container-se {
  position: absolute;
  width:100%;
  height:calc( 100% - 60px ) !important;
  margin-right:0px;
  margin-top:60px;
  box-shadow: unset;
  border-radius: unset;
}

  .simplebar-se  {
      height: calc(70vh - 25px);
  }

  .right-container-se {
      display:none;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      width:80vw;
      z-index:1000;
      height:70vh;
      box-shadow:0 1px 4px rgba(0,0,0,0.16), 0 1px 4px rgba(0,0,0,0.23);
  }

  #statistik-sicon {
    display:block;
    }
   
  
}


@media only screen and (max-width : 1023px) and (orientation : landscape)  {

.left-container-se {
  position: absolute;
  width:100%;
  height:100vh !important;
  margin-right:0px;
  margin-top:0px;
  box-shadow: unset;
  border-radius: unset;
}

  .simplebar-se {
      height: calc(70vh - 25px);
  }

  .right-container-se {
       display:none;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      width:60vw;
      z-index:1000;
      height:70vh;
      box-shadow:0 1px 4px rgba(0,0,0,0.16), 0 1px 4px rgba(0,0,0,0.23);
  }

  #statistik-sicon {
    display:block;
    }

    
}

.custom-date-input {
  cursor: pointer;
  display: flex;
  align-items: center;
  overflow: hidden;
  margin-left: 10px;
}

.select-date-input {
  display: none;
  height: 0px;
}