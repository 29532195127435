

#paper {
   width:calc(100vw - 495px);
}

#paper  .MuiButtonBase-root {
    padding: unset !important;
} 


#paper .MuiInput-underline {
    width:140px !important;
} 

#paper input:focus {
    outline: none;
    border:none !important ;
   }    

.icons-container {
    right:0px;
    position: absolute;
    top:5px;
    z-index:1000;
    display:flex;
     margin-right: 390px;
     width:50px;
     display: flex;
     align-items: center;
}

.dx-toolbar .dx-toolbar-after {
    padding-left: 10px;
    padding-right:10px;
    padding-top:5px;
}

.export-icons {
    height:22px;
    width:22px;
    cursor:pointer;
  }

.park-container {
    display:flex;
    align-items: center;
    font-size: 12px;
    cursor:pointer; 
    border-radius: 3px; 
}

#settings-icon {
    height:20px;
width:20px;
cursor:pointer;
margin-left:10px; 
display:none;
  }

.input-we {
    width:100px;
}

.input-we2 {
    width:70px;
}
  


 /*Tablets*/   

@media only screen and (min-width:768px) and (max-width : 1024px) and (orientation : portrait),  
only screen and (min-width:1024px) and (max-width : 1366px) and (orientation : landscape)
{

    #paper {
        width:100%;
     }

    .icons-container {
        position: absolute;
        top:calc( 100% - 45px );
        left:10px; 
        margin-right:0px;  
    }

    #settings-icon {
    display:block;
    }

    .export-icons {
        height:20px;
        width:20px;
      }
      
      .input-we {
        width:140px;
    }
    
    .input-we2 {
        width:110px;
    }
}




@media only screen and (max-width : 767px) and (orientation : portrait)  {

    #paper {
        width:100%;
     }

    .icons-container {
        position: absolute;
        top:calc( 100% - 45px );
        left:10px; 
        margin-right:0px;    
    }

    #settings-icon {
    display:block;
      }
      
      .export-icons {
        height:20px;
        width:20px;
      }
    
}


@media only screen and (max-width : 1023px) and (orientation : landscape)  {

    #paper {
        width:100%;
     }

    .icons-container {
        position: absolute;
        top:calc( 100% - 45px );
        left:10px;   
        margin-right:0px;  
    }

    #settings-icon {
    display:block;
      }

      .export-icons {
        height:20px;
        width:20px;
      }
      
}