

/* menu */

.vertical-menu {
    -webkit-transform: translate3d(0%, 0px, 0px);
    transform: translate3d(0%, 0px, 0px);
    visibility: visible;
    transition: all 0.5s ease 0s;
    -webkit-transition: all 0.5s ease 0s;
    display:block !important;
    width:120px;
   background-color:var(--name-bckg-color);
top:60px;
   bottom: 0;
   margin-top: 0;
   position: fixed;;
   z-index: 5000;
}


.vertical-menu-open {
    -webkit-transform: translate3d(0%, 0px, 0px) !important;
    transform: translate3d(0%, 0px, 0px) !important;
}

.mm-active .active  {
    color: #fff!important;
}

.mm-active .active i {
    color: #fff!important;
}

 #sidebar-menu ul li a {
    color: #a6b0cf;
    display: flex;
    flex-direction: column;
    width:100%;
    align-items: center;
    justify-content: center;
}

#sidebar-menu ul li a:hover {
    color: #fff
}

.menu-title {
    padding: 12px 20px !important;
    letter-spacing: .05em;
    pointer-events: none;
    cursor: default;
    font-size: 11px;
    text-transform: uppercase;
    color: #7f8387;
    font-weight: 600;
}

#sidebar-menu ul {
     padding-left: 0; 
     list-style: none;
width:100%;
}

#sidebar-menu li {
text-align: center;
display: flex;
justify-content: center;
width:100%;
}

.main-content {
    margin-left:120px !important;
}

/* header */

header {
    height:60px !important;
    display:flex !important;
    align-items: center !important;
    justify-content: center !important;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1002;
    background-color: #ffffff;
    box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%);
}


.header-item {
    box-shadow: none !important;
    border: 0;
    border-radius: 0px;
}

.header-profile-user {
    height: 36px;
    width: 36px;
    padding: 3px;
}

.noti-icon i {
        font-size: 22px;
}

.logo {
    height:25px;
    width:110px;
}


#burger-btn {
    display:none;
    font-size:16px;
    margin:4px 10px 0px 10px;
}



/* dropdown-header */

.dropdown-item {
  margin: auto !important;
   display:flex !important;
   justify-content: center; 
   align-items: center;
   font-size:13px;
}

.dropdown-item:focus {
background-color:#f8f9fa ;
color:#212529;
  }


#dropdown-defect-elements  table {
    margin-top:5px;
 width: unset;
 font-size:10px;
 margin:auto;
 } 
 #dropdown-defect-elements  th {
min-width: 100px;
font-weight: bold;
} 

#dropdown-defect-elements td {
    min-width: 70px;
 }




@media only screen and (min-width:300px) and (max-width : 767px) {

    .profile-name {
        display:none;
    }

    .vertical-menu {
        -webkit-transform: translate3d(-100%, 0px, 0px);
        transform: translate3d(-100%, 0px, 0px);
    }

    .main-content {
        margin-left:0px !important;
    }

    #burger-btn {
        display:block;
    }

    .logo {
        margin-top:-5px;
        height:23px;
        width:100px;
    }



}
@media only screen and (min-width:300px) and (max-width : 900px) and (orientation:landscape) {

    header {
        display:none !important;
    }

}


@media only screen and (min-width:768px) and (max-width : 1366px) {

    .vertical-menu {
        -webkit-transform: translate3d(-100%, 0px, 0px);
        transform: translate3d(-100%, 0px, 0px);
    }

    
    .main-content {
        margin-left:0px !important;
    }

    #burger-btn{
        display:block;
    }

    .logo {
        margin-top:-5px;
        height:23px;
        width:100px;
    }


}