.inputnumber {
    border:0px  ;
    margin-right:5px;
    height:100%;
    width:100%;
}

.inputtime {
    border:0px  ;
    margin-right:15px;
    height:18px;
}

.inputwrapper {
    display:flex;
    align-items: center;
    width:80px;
    height:18px;
}

.inputtext{
    position: absolute;
    right:5px;
}

.context-checkbox {
    font-size:11px;
    right:5px;
    position: absolute;
 }


 .react-contextmenu-numeric {         
        border:none !important;
        border-radius: none !important;
        position: relative;
       z-index:0 !important;            
 }

 .react-contextmenu-numeric:focus {         
    border:none !important;
    border-radius: none !important;          
}


.contextmenu{
    display:none;
    position: absolute !important;
    z-index: 10000;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: .25rem;
    font-size: 12px;
    min-width: 150px;
    outline: none;
    text-align: center;
}

.react-contextmenu {
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: 4px;
    font-size: 12px;
    min-width: 150px;
    outline: none;
    display:none;
    opacity:0;
    text-align: center;
}

.submenu {
    margin-top:-1px;
    font-size:12px;
}

.submenu-icon {
    position:absolute;
    top:7px;
    right:5px;
     font-size:10px;
}


.react-contextmenu.react-contextmenu--visible {
    display:block;
    opacity:1;
    pointer-events: auto;
    z-index: 10000;
}

.react-contextmenu-item {
    background: 0 0;
    border: 0;
    font-weight: 400;
    padding:3px;
    text-align: inherit;
    white-space: wrap;
    display: flex;
    justify-content: center;
    width:100%;
}

.react-contextmenu-item-send {
    background-color: #f0f0f5;
}

.react-contextmenu-item-send:hover {
    background-color: #f0f0f5 !important;
}


.react-contextmenu-item.react-contextmenu-item--active,
.react-contextmenu-item.react-contextmenu-item--selected {
    color: inherit;
    background-color: transparent;
    border-color: transparent;
    text-decoration: none;
    cursor: pointer;
}

.activeitem:hover {
    color: inherit;
    background-color: transparent;
    border-color: transparent;
    text-decoration: none;
    cursor: pointer;
}

.react-contextmenu-item.react-contextmenu-item--disabled,
.react-contextmenu-item.react-contextmenu-item--disabled:hover {
    background-color: transparent;
    border-color: rgba(0,0,0,.15);
    color: inherit;
    cursor: default !important;
}

.react-contextmenu-item--divider {
    border-bottom: 1px solid rgba(0,0,0,.15);
    cursor: inherit;
    margin-bottom: 0px;
    padding: 0px 0px !important;
}
.react-contextmenu-item--divider:hover {
    background-color: transparent;
    border-color: rgba(0,0,0,.15);
}

.react-contextmenu-item.react-contextmenu-submenu {
 padding:0;
 cursor:pointer !important;
}
