.svg-carfinder {
    height:75vh;
    width:90vw;
}

.left-part-carfinder {
    margin-right: 0px;
}

.search-carfinder {
    margin:auto;
    position:absolute;
    top:0;
     left:0;
     right:0;
     z-index: 100;
     padding-top: 20px;
     width:300px
}

#dropdown-carfinder {
    width:260px;
    z-index: 10001;
    top:50px;
    position:absolute;
    background-color: white;
    box-shadow:0 1px 4px rgba(0,0,0,0.16), 0 1px 4px rgba(0,0,0,0.23);
    border-radius:4px;
}


#dropdown-carfinder ul {
    list-style: none;
    padding: 3px;
    margin: 0;
    border-radius:4px;
  }

#dropdown-carfinder li:hover {
    color: #fff;
    background-color: var(--selected-blue);
    border-color: var(--selected-blue);
    text-decoration: none;
    cursor: pointer;
  }


  .camera-container {
    z-index:1000;
     position: absolute;
      margin:auto;
      left: 0;
      right: 0;
      top:0;
      bottom:0;
      width:400px;
      height:400px;
  }

  .camera-name {
    background-color: #2a3042;
    color:white;
    width:100%;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    padding:4px 4px;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
  }