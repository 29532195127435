.flex-sa {
    display:flex;
  justify-content: space-around;
  align-items: center;
  }

  .flex-sa2 {
    display:flex;
  justify-content: space-around;
  }
  
  .flex-sb {
  display:flex;
  justify-content: space-between;
  align-items: center;
  }
  
  .flex-iac {
    display:flex;
    align-items: center;
  }

  .flex-sb2 {
    display:flex;
    justify-content: space-between;
  }


  .flex-c {
    display:flex;
    justify-content: center;
    align-items: center;
  }

  .flex-col {
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .flex-col-1 {
    display:flex;
    flex-direction: column;
  }