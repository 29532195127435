.box-dashboard {
    background-color: white;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.16), 0 1px 4px rgba(0, 0, 0, 0.23);
    border-radius: 4px;
    height: 300px;
    min-width: 420px;
    overflow: hidden;
    margin-right: 50px;
}

.box-dashboard2 {
    margin: 40px 20px 0px 20px;
    background-color: white;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.16), 0 1px 4px rgba(0, 0, 0, 0.23);
    border-radius: 4px;
    height: 130px;
    width: 380px;
    overflow: hidden;
}

.box-name {
    background-color: #2a3042;
    color: white;
    width: 100%;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    padding: 3px 3px;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
}

#dashboard .info-icon-container {
    display: none;
    align-items: center;
    justify-content: flex-end;
    height: 30px;
    width: 100%;
    padding-right: 10px;
    margin: auto;
}


#dashboard td,
#info td {
    text-align: center;
    min-width: 30px;
    font-size: 11px;
    position: relative;
    vertical-align: top;
}

#dashboard th,
#info th {
    text-align: center;
    min-width: 30px;
    font-size: 10px;
    font-weight: 400;
}

#dashboard tr:nth-child(1) {
    font-weight: bold;
}

#dashboard table,
#info table {
    font-size: 10px;
    word-wrap: normal;
    white-space: normal;
    width: 100%;
    border-collapse: separate;
    border-spacing: 2px;
    position: relative;
}


#dashboard .td-first,
.th-first {
    text-align: left;
    width: 115px;
}

#dashboard .chart-name {
    top: 15px !important;
    left: 20px;
}


#info table {
    margin-top: 5px;
    width: unset;
}

#info th {
    min-width: 70px;
    font-weight: bold;
}

#info td {
    min-width: 70px;
}



.first-part {
    margin-top: 30px;
    width: 90%;
    display: flex;
    justify-content: space-around;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    flex-wrap: wrap-reverse;
}

.second-part {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 90%;
    display: flex;
    justify-content: space-around;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    flex-wrap: wrap;
}

.responsive-container {
    flex-grow: 1;
    width: 60% !important;
    height: 330px !important;
}

.css-1jllj6i-control {
    display: none !important;
}

.css-1vr111p-option,
.css-1qprcsu-option {
    display: flex !important;
    align-items: center !important;
}

.css-48ayfv,
.css-1r4vtzz {
    width: 100% !important;
}

.css-ik6y5r {
    box-shadow: 0 1px 4px rgb(0 0 0 / 16%), 0 1px 4px rgb(0 0 0 / 23%) !important;
    border-radius: 4px;
}

.css-11unzgr {
    min-width: 311px;
}

.css-1v99tuv {
    font-size: 12px !important;
}


@media only screen and (min-width:768px) and (max-width : 1024px) and (orientation : portrait),
only screen and (min-width:1024px) and (max-width : 1366px) and (orientation : landscape) {






    #dashboard .info-icon-container {
        display: flex;
    }


    .responsive-container {
        flex-grow: unset;
        width: 90% !important;
        height: 400px !important;
        margin: auto;
        margin-top: 40px;
        margin-bottom: 20px;
    }

    #dashboard .chart-name {
        left: 5px;
    }

    .box-dashboard {
        margin-right: 0px;
        min-width: 0px;
        width: 450px;
        height: 200px;
    }

    .box-dashboard2 {
        margin: 20px 0px 0px 0px;
        width: 450px;
    }

    .first-part {
        margin-top: 0px;
        width: 95%;
    }

    .second-part {
        margin-top: 0px;
        width: 95%;
    }


}




@media only screen and (max-width : 767px) and (orientation : portrait) {

    #dashboard .info-icon-container {
        display: flex;
    }

    .responsive-container {
        position: absolute;
        visibility: hidden;
    }


    .box-dashboard {
        height: 200px;
        min-width: 0px;
        width: 100%;
        margin-right: 0px;
        margin-top: 0px;
    }

    .box-dashboard2 {
        margin: 20px 0px 0px 0px;
        width: 100%;
    }


    .first-part {
        margin-top: 0px;
        width: 95%;
        flex-wrap: wrap-reverse;
    }

    .second-part {
        margin-top: 0px;
        width: 95%;
        flex-wrap: wrap;
    }




}


@media only screen and (max-width : 1023px) and (orientation : landscape) {

    #dashboard .chart-name {
        left: 0px;
    }


    .responsive-container {
        flex-grow: unset;
        margin-top: 10px;
        margin-bottom: 0px;
        width: 97% !important;
        height: 95vh !important;
    }

    .box-dashboard,
    .second-part {
        display: none;
    }

    #dashboard .info-icon-container {
        display: none;
    }



    .first-part {
        margin-top: 0px;
        width: 100%;
        justify-content: center;
    }




}

#lastActivityDashboardTable th,
#lastActivityDashboardTable td {
    min-width: 0px;
}
#lastActivityDashboardTable td {
    vertical-align: top;
}
