

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/montserrat-regular.ttf') format('truetype'),;
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/poppins-regular.ttf') format('truetype'),;
}

@import "./general.css";  
@import "./overwrites.css";  
@import "./flexbox.css";  

/* import pages */

@import "./occupancy/occupancy.css";
@import "./events/events.css";
@import "./statistics/statistics.css";
@import "./dashboard/dashboard.css";
@import "./calendar/calendar.css";
@import "./settings/settings.css";

@import "./login/login.css";

@import "./profile/profile.css";
@import "./defectElements/defectElements.css";
@import "./systemInfo/aliveStatus.css";
@import "./carFinder/carFinder.css";
@import "./lastActivity/lastActivity.css";
@import "./specialSettings/specialSettings.css";
@import "./timeouts/timeouts.css";
@import "./header_menu/header_menu.css";


:root {
    --selected-gray: #e9ecef;
    --selected-blue: #20a0ff ;
    --border-color: #dadadb;
    --name-bckg-color: #2a3042;
    --border-radius: 3px;
    --input-height: 25px;
    --button-height: 25px;
    --maincontent-bs:0 1px 2px rgba(0,0,0,0.22);
  }


 