.profile-div {
    width:500px;
}



@media only screen and (min-width:768px) and (max-width : 1024px) and (orientation : portrait),  
only screen and (min-width:1024px) and (max-width : 1366px) and (orientation : landscape)
{
  
}




@media only screen and (max-width : 767px) and (orientation : portrait)  {

    .profile-div {
        width:100%;
    }
}


@media only screen and (max-width : 1023px) and (orientation : landscape)  {
    .profile-div {
        width:100%;
    }
    
}