
  /* Body */ 

body {
  width:100vw;
  position: fixed; 
  height:100vh;
  font-size: 12px;
  margin: 0;
    font-family: "Poppins", sans-serif;
    font-size: 0.8125rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    text-align: left;
    background-color: #f8f8fb;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

 *::before, *::after {
  box-sizing: border-box;
}

.loader {
  width:100%;
  height:100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction:column;
  z-index:1000;
  position: absolute;
  background-color: #f8f8fb;
}

.loader-mini {
  width:100%;
  height:100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction:column;
  z-index:1000;
  position: absolute;
  background-color: unset;
}

   /* Scrollbar style */ 
      
   ::-webkit-scrollbar {
    width: .35em; 
    height: .35em;
    }
  
    ::-webkit-scrollbar,
    ::-webkit-scrollbar-thumb {
      overflow:visible;
      border-radius: 8px;
    }
  
    ::-webkit-scrollbar-thumb {
      background: rgba(0,0,0,.2); 
    }   


  /* popup divs */ 

.content {
  width:100%;
  display:flex;
  flex-direction: column;
  align-items: center;
  font-size:12px;
  }

  .content-name {
      background-color: var(--name-bckg-color);
      color:white;
      width:100%;
      text-align: center;
      font-size: 12px;
      font-weight: 500;
      padding:4px 4px;
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
    }

    .content-group-name {
        border-bottom: 1px solid var(--border-color);
        font-weight: 500;
        margin-bottom:15px;
    }

    .content-group {
        display:flex;
        flex-direction: column;
        width:90%;
        margin-top:15px;        
    }



 /* input */ 

 label {
  font-weight: 400 ;
}


       input[type="radio"] {
        transform: scale(0.8); 
      }

  
  
        
          input {
            border:1px var(--border-color) solid ;
            border-radius: var(--border-radius); ;
            height:var(--input-height);
            color:unset;
          }

          input::placeholder,.form-control {
            font-size:12px;
          }

          input:focus,.form-control:focus {
            outline: none;
            border:1px solid #80bdff !important ;
            box-shadow: none;
           }   

           .form-control.is-invalid:focus {
            box-shadow: none;
           }
        

           textarea {
           border:1px var(--border-color) solid ;
           border-radius: var(--border-radius);
           }

           textarea:focus {
            outline: none;
            border:1px solid #80bdff !important ;
           } 


            /* classic button */ 

           .button {
            width:80px;
            background: none;
            border:1px solid var(--border-color);
            border-radius: var(--border-radius);
            height:var(--button-height);
            color:unset;
          }

          .button-right {
            position: absolute;
            right:15px;
            bottom:10px
          }

          .button-left {
            position: absolute;
            left:15px;
            bottom:10px
          }

          .btn {
            font-size:13px;
          }
        
           .button:hover{
            color: #fff;
            background-color: var(--selected-blue);
            border-color: var(--selected-blue);
            text-decoration: none;
            cursor: pointer;
            }

            .button:focus, button:focus {
              outline: none !important;
            }
        
           .button-active{
             color: #fff;
             background-color: var(--selected-blue);
             border-color: var(--selected-blue);
             text-decoration: none;
             cursor: pointer;
             }


             /* custom input datetime */ 

             .datetime-picker {
               width:100% !important;
               height:var(--input-height) !important;
             }

             .time-input {
               width:80px;
             }    
         
             /* select image */ 
             
             .select-icon {
               width:16px;
               height:16px;
               margin-right:5px;
             }    

           /* custom input number */ 
 
             .input-numeric {            
               border:1px var(--border-color) solid !important;
               border-radius: var(--border-radius) !important;
               position: relative;
              z-index:0 !important;            
             }

             .input100 {            
                width:100%;        
            }

            .input70 {            
              width:70px;        
          }

             /* input number arrows */ 

             b {
              border-color: rgba(107, 107, 107, 0) !important;
              background: rgba(107, 107, 107, 0) !important;
              margin-right:-2px !important;
             }

             b:hover {
              border-color: rgba(0, 0, 0, 0.1) !important;
              background: rgba(0, 0, 0, 0.1) !important;
             }


             /* page wrapper for desktops */ 
         
           @media only screen  and (min-width : 1367px)   {

            .pagewrapper {
              height: calc(100vh - 99px);
              display:flex;
              justify-content:space-between;
              margin: 70px 10px 10px 10px;
              position: relative;
             }

             .pagewrapper-2 {
              height: calc(100vh - 80px);
              display:flex;
              justify-content:space-between;
              margin: 70px 10px 0px 10px;
              position: relative;
             }
  
           }