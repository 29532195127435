.mapFS {
    width:100vw !important;
    top:0px !important;
    left:0px !important;
    height:100vh !important;
    right:0px !important;
    z-index:5000 !important;
    position: absolute !important;
    margin-left:unset !important;
    margin-right:unset !important;
  }
  
  .firstrowFS {
      top:5px !important;
      left:0px !important;
      z-index:5001 !important;
      width:99vw !important;
      position: absolute !important;
  }
  
  .parkcurrentFS {
      left:80px !important;
      position: absolute !important;
  }
  
  .treeTabletFS {
      display:unset !important;
      left:4px !important;
      position: absolute !important;
  }

  .legendFS {
    right:20px !important;
    position: absolute !important;
  }
  
  .parktreeallFS{
      z-index:5002 !important;
      height:100vh !important;
      border-radius: unset !important;
      top: 0px !important;
      display:none;
      position: absolute !important;
  }
  
  .svgFS {
      width:100vw !important;
      height:100vh !important;

  }

  .pagewrapperFS {
    height: unset !important;
    display:unset !important;
    justify-content:unset !important;
    margin: unset !important;
    position: unset !important;
   }

  .bodyoverlayFS {
    z-index:5001 !important;

}