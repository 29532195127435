#popUp {
    background-color: white;
    width:600px;
    height:450px;
    position: absolute;
    margin:auto;
    left: 0;
    right: 0;
    top:0;
    bottom:0;
    z-index:1000;
    box-shadow:0 1px 4px rgba(0,0,0,0.16), 0 1px 4px rgba(0,0,0,0.23);
    border-radius:4px;
}


#popUp .div-name {
    background-color: #2a3042;
    color:white;
    width:100%;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    padding:4px 4px;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
}

/* overwrites */

.tui-full-calendar-popup-container {
    height: 100%;
    width:100%;
    min-width: unset !important;
    min-height: unset !important;
}

 .tui-full-calendar-dragging {
cursor:unset !important;
}

.tui-full-calendar-weekday-schedule-block-dragging-dim {
    opacity:1 !important;
}  

.tui-full-calendar-weekday-filled {
    background-color:unset !important;
    }

.tui-full-calendar-month-guide-focused, .tui-full-calendar-month-guide, .tui-full-calendar-month-guide-cover, .tui-full-calendar-weekday-resize-handle {
    display:none !important;
}


.tui-full-calendar-month {
    min-height: unset !important;
}





.title-calendar {
    width:100%;
    background-color: unset !important;
    border:unset !important;
    margin-left:5px;
    height:unset !important;
}

.title-calendar:focus {
    border:unset !important;
}


.title-calendar-container {
    width:100%;
    border:1px var(--border-color) solid ;
    border-radius: var(--border-radius); ;
    height:var(--input-height);
    color:unset;
}

.title-calendar-datetime {
    height:100%;
    display:flex;
    align-items: center;
}

.title-calendar-span {

    height:100%;
    display:flex;
    align-items: center;
}





.list-elements {
    width: 100%;
    position: relative;
    border: 1px solid var(--border-color);
    border-radius: 3px;
    -webkit-user-select: none;
    user-select: none;
}

/* #popUp .react-numeric-input {
    width:100% ;
} */


.MuiFormControlLabel-label  {
    font-size: 12px !important;
    font-family: "Poppins", sans-serif !important;
    font-size: 0.8125rem !important;
    line-height: 1.5;
    letter-spacing: unset !important;
    user-select: none;
}


.MuiFormControlLabel-labelPlacementStart {
    margin-top: -10px !important;
     margin-left: unset !important;
     margin-right:  unset !important;
}





#popUp .align-button {
    width: 30px;
    height:24px;
    border-radius: 3px;
    background: none;
    display: flex;
    justify-content: center;  
    align-items: center;
    box-sizing: border-box;
    font-size:16px;
    color: #a6a6a6;
    cursor: pointer;
    outline: none;
    border: none;
 }
 
 #popUp .selected-align-button {
  border: 1px solid var(--selected-blue);
 }

 #popUp .color-input {
 height:100%;
 width:40px;
 background-color:unset;
}


.day-cal {
  height:25px;
  background-color: rgb(233, 236, 239);
  padding-left:15px;
  padding-right:15px;
}


.schedule-cal {
    padding-left:15px;
  padding-right:15px;
  height:25px;
  background-color: white;
 
  
}


.holiday {
    color:red !important;
}


.mobile-schedules-icon {
    display:none;
    height:16px;
    width:16px;
}



.calendar-dates {
    width:100%;
    margin-top:15px;
    margin-bottom:15px;
    display:flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
}

.number-color-text-container {
    margin-top:15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.color-text-container {
    display:flex;
    width:100%;
    margin-left:5px;
    justify-content: space-between;
}

.calendar-num {
    width:70px;
    margin-left: 5px;
}

.calendar-container-schedule {
    display:flex;
    justify-content: space-between;
    flex-direction: row;
   }       
      
  .schedule-selection-part {
      width:48%;
      margin-top:15px
  }


  #schedule-light-container .react-numeric-input {
      width:100% !important;
  }


@media only screen and (min-width:768px) and (max-width : 1024px) and (orientation : portrait),  
only screen and (min-width:1024px) and (max-width : 1366px) and (orientation : landscape)
{
    #popUp {
        height:500px;
        width:600px ;
        }

        .mobile-schedules-icon {
            display:block;
        }
}




@media only screen and (max-width : 767px) and (orientation : portrait)  {

    #popUp {
        height:60vh;
        width:90vw ;
        }

        .mobile-schedules-icon {
            display:block;
        }

        .calendar-dates {
            width:100%;
            margin-top:15px;
            margin-bottom:15px;
            display:flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
        }

        .title-calendar-datetime {
            width:100%;
        }

        .title-calendar-span {
            visibility: hidden;
            height:15px
        }


.calendar-num {
    width:120px;
} 
        
.calendar-container-schedule {
    display:flex;
    justify-content: space-between;
    flex-direction: column;
   }       
      
   .schedule-selection-part {
      width:100%;
      margin-top:15px;
  }

}


@media only screen and (max-width : 1023px) and (orientation : landscape)  {
    #popUp {
    height:80vh;
    width:80vw ;
    }


    .mobile-schedules-icon {
        display:block;
    }
    
}