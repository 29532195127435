#login-box {
width:480px;
height:480px;
position: absolute;
background-color: rgb(255, 255, 255,0.95);
margin:auto;
top:0;
bottom:0;
right:0;
left:0;
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
padding-top: 10px;
padding-bottom:10px;
border-radius: 5px;
box-shadow:0 1px 4px rgba(0,0,0,0.16), 0 1px 4px rgba(0,0,0,0.23);
}

#ReactBackgroundSlider figure{
    filter: brightness(60%);

}

.login-logo {
   margin:auto;
   display:block;
   margin-bottom:25px;
   height:28px;
}


.login-loader {
    width:100vw;
    height:100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction:column;
    background-color: rgb(0, 0, 0,0.8);
    z-index:10000;
    position: absolute;
}

.invalid-feedback { 
position: absolute !important;
}

.mt-3{
    margin-top:30px !important;
}

.login-fail {
font-size:80%;
color:#f46a6a;
text-align:center;
position: relative;
padding-top:10px;
height:20px;
display:flex;
align-items: center;
justify-content: center;
}




@keyframes imageAnimation {
0% {
    opacity: 0;
    animation-timing-function:  ease

}

2% {
    opacity: 1;
    animation-timing-function:  ease
   
}

25% {
    opacity: 1;
    
}

28% {
     opacity:  0; 
    
}

100% {
     opacity:  0;  
    
}

}


@media only screen and (min-width:768px) and (max-width : 1024px) and (orientation : portrait),  
only screen and (min-width:1024px) and (max-width : 1366px) and (orientation : landscape)
{
    #login-box {
        margin:auto;
        top:0;
        bottom:0;
        right:0;
        left:0;
        }
}




@media only screen and (max-width : 767px) and (orientation : portrait)  {

    #login-box {
        width:90%;
        height:400px;
        margin:auto;
        top:0;
        bottom:0;
        right:0;
        left:0;
        }
        
        
}


@media only screen and (max-width : 1023px) and (orientation : landscape)  {
    #login-box {
        width:60%;
        height:350px;
        margin:auto;
        top:0;
        bottom:0;
        right:0;
        left:0;
        }
    
}