/* react-confirm-alert */

.react-confirm-alert-body {
  width:unset !important;
  text-align: center !important;
}

.react-confirm-alert-button-group {
  width:120px !important;
  justify-content: space-between !important;
}

.react-confirm-alert-button-group>button {
  margin-right:unset !important;
}


/* devexpress table */

 .MuiTableCell-root {
    padding: 8px !important;
    white-space: unset !important;
}

.MuiPopover-root {
  z-index:10000 !important;
}

.MuiInputBase-input, .MuiTypography-body2, .MuiMenuItem-root, .MuiInputBase-root, .MuiTableCell-root, .MuiTableCell-body  {
    font-size: 12px !important;
    font-family: "Poppins", sans-serif !important;
    color: unset !important;
}

.MuiTypography-body1 {
  font-size: 12px !important;
  font-family: "Poppins", sans-serif !important;
    color: unset !important;
}


/* Material UI Checkbox */

.MuiSvgIcon-fontSizeSmall {
  font-size: 1rem !important;
}


.MuiCheckbox-colorPrimary.Mui-checked {
  color: #1976d2 !important;
}



/* time picker input */

.MuiTextField-root {
  border:1px var(--border-color) solid !important;
  border-radius: 3px !important;
}

.MuiInputBase-inputAdornedEnd {
  border: none !important;
}

.MuiInputBase-inputAdornedEnd:focus {
  border: none !important;
}


.MuiOutlinedInput-notchedOutline {
  border-style: none !important;
}

.MuiInputBase-input {
  padding:3px !important;
}

.MuiSvgIcon-root {
  width: 15px !important;
  height: 15px !important;
}

.rc-time-picker-panel {
  z-index: 10000;
}

.rc-time-picker-input {
    height:var(--input-height) !important;
  }

  .rc-time-picker-clear {
    line-height: unset !important;
  }
 
  .rc-time-picker-panel-select {
    scrollbar-width: thin; 
   scrollbar-color: #e3e3e3 transparent;
  }

  .rc-time-picker-panel-input, .time-input > ::-webkit-input-placeholder {
text-align: center;
font-size: 11px;
}

.rc-time-picker-panel-input, .time-input >:-moz-placeholder { /* Firefox 18- */
text-align: center;  
font-size: 11px;
}

.rc-time-picker-panel-input, .time-input >::-moz-placeholder {  /* Firefox 19+ */
text-align: center;  
font-size: 11px;
}

.rc-time-picker-panel-input, .time-input > :-ms-input-placeholder {  
text-align: center; 
font-size: 11px;
}


/* datetime picker */

.rw-popup-container {
z-index: 3500 !important;
}

.rw-widget-container {
    height: var(--input-height)!important;
    min-height: var(--input-height) !important;
    box-shadow:unset !important;
   }

   .rw-input-addon {
    height: 23px !important;
    min-height: 23px !important;

   }

   .rw-state-focus {
     box-shadow:unset !important;
     border:unset !important;
   }

   .rw-widget-input:focus {
    border:unset !important;
   }

   .rw-cell.rw-state-selected {
    border-color: var(--selected-blue) !important ;
    background-color:var(--selected-blue) !important ;
   }

/* badge defects */

.badge {
  top:-10px !important;
}



/* multiselect */

   .multi-select {
    --rmsc-main: var(--selected-blue);
    --rmsc-hover: unset;
    --rmsc-selected: unset;
    --rmsc-border: #ccc;
    --rmsc-gray: #aaa;
    --rmsc-bg: #fff;
    --rmsc-p: 12px; /* Spacing */
    --rmsc-radius: 3px; /* Radius */
    --rmsc-h: 25px; /* Height */
    user-select: none;
    scrollbar-width: none !important;
    scrollbar-color: white transparent;
 

  }


  .go2646822163:focus-within {
     border: 1px solid var(--selected-blue);
     box-shadow: unset !important;
  }



  .recharts-tooltip {

  margin: 0px;
  padding: 10px;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(204, 204, 204);
  white-space: nowrap;

}


.recharts-item {

  display: block;
padding-top: 4px;
padding-bottom: 4px;
color: rgb(255, 136, 60);

}


