
 #timeoutsTable td{
    text-align: left ; 
    width: 33.33%;
    position: relative;
    border: 1px solid silver;
    padding:5px;
 }

 #timeoutsTable {
     height:100%;
     margin: auto;
     width:60%
 }

 #timeoutsTable th{
    text-align: left ; 
    padding:5px;
    font-weight: 400;
    border: 1px solid silver;
    width: 33.33%;
 } 
 
 
 #timeoutsTable table {
     word-wrap:normal;
     white-space: normal;
     border-spacing:2px;
     position: relative;
     width:100%;
 }

 .left-part-timeouts {
    margin-right: 0px;
 }

 .simple-timeouts {
height:calc(100% - 45px)
 }
 

 @media only screen and (min-width:768px) and (max-width : 1024px) and (orientation : portrait),  
only screen and (min-width:1024px) and (max-width : 1366px) and (orientation : landscape)
{
    #timeoutsTable {
        width:80%
    }

    .simple-timeouts {
        height:calc(100vh - 40px)
         }
         
}


@media only screen and (max-width : 767px) and (orientation : portrait),
only screen and (max-width : 1023px) and (orientation : landscape)   {

    #timeoutsTable {
        width:100%
    }
    .simple-timeouts {
        height:calc(100vh - 40px)
         }


}

